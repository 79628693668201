import React, {useEffect, useMemo, useState} from 'react';
import {
    FlexColumnSection,
    FlexRowSection,
    SeparatorVerticalDiv,
    SmallHeader,
    SmallText
} from "../../styles/globalStyles";
import CustomCollapse from "../../components/UI/CustomCollapse";
import useScreenSize from "../../helpers/hooks/useScreenSize";
import {useDispatch, useSelector} from "react-redux";
import {apiAction} from "@arboxappv4/shared/src/helpers/HTTP";
import {t} from "i18next";
import {
    CONFIRMATION_MODAL, LOGIN_MODAL,
    openModal,
    SELECT_QUANTITY_MODAL, updateModalRes,
} from "@arboxappv4/shared/src/redux/modalManagerSlice";
import {sortAlphabetically} from "@arboxappv4/shared/src/helpers/functions";
import {Colors} from '@arboxappv4/shared/src/styles/Colors'
import styled from "styled-components";
import {Spin} from "antd";
import {
    addFiltersToQueryParams, checkIfHPGroupDisplay, getHPGroupIdFromLocation, getHPGroupsForPurchase,
    getLocationsForPurchase,
    getMembershipExpirationText,
    getMembershipPriceText, handleMembershipStartDate, onLinkClicked,
} from "../../helpers/functions";
import CustomSelect from "../../components/UI/CustomSelect";
import useMembershipStartDate from "../../helpers/hooks/useMembershipStartDate";
import {updateFlags, updateToastMessage} from "@arboxappv4/shared/src/redux/stepperSlice";
import {useNavigate, useSearchParams} from "react-router-dom";
import {membershipTypes} from "@arboxappv4/shared/src/helpers/constants";
import CartBanner from "../../components/CartBanner";
import {pixelEvents, sendPixelEvent} from "../../helpers/hooks/UsePixel";

const Memberships = (props) => {
    const { values, errors, handleSubmit, setFieldValue, skipStep, blockOnLoggedOut, checkIfNoMembershipAllowed, HPGroupDisplay, hideLocation, showCart } = props;
    const box = useSelector(state => state.site.box)
    const globalLocation = useSelector(state => state.site.selectedLocation)
    const quantitySessionRes = useSelector(state => state.modalManager[SELECT_QUANTITY_MODAL].processRes)
    const flags = useSelector(state => state.stepper.flags)
    const loggedIn = useSelector(state => state.auth.loggedIn)
    const [memberships, setMemberships] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(values.locations_box_fk ?? null);
    const [selectedHPGroup, setSelectedHPGroup] = useState(null);
    const [limitations, setLimitations] = useState({});

    const {isMobile} = useScreenSize()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);

    const isHPGroupDisplay = useMemo(() => box && HPGroupDisplay ? checkIfHPGroupDisplay() : false,[box])
    const locations = useMemo(() => isHPGroupDisplay ? [] : getLocationsForPurchase(checkIfNoMembershipAllowed),[box, isHPGroupDisplay])
    const groups = useMemo(() => isHPGroupDisplay ? getHPGroupsForPurchase() : [],[box, isHPGroupDisplay])
    const allowCart = useMemo(() => showCart && box.boxes_settings?.some(setting => setting.prop_name === 'siteCart' && setting.properties && setting.properties.active), [box, showCart])
    const purchaseLimitations = useMemo(() => box.boxes_settings?.find(setting => setting.prop_name === 'sitePurchaseLimitations' && setting.properties && setting.properties.active), [box])
    const hasPurchaseLimitations = useMemo(() => !!purchaseLimitations, [purchaseLimitations])
    const cart = useMemo(() => values?.cart ?? [], [values?.cart]);
    const cartHasRecurring = useMemo(() => cart.some(item => item.is_recurring_payment), [cart]);
    const maxSessionQuantity = useMemo(() => {
        if(purchaseLimitations?.properties?.sessionsPerDay) {
            const quantityInCart = cart.reduce((count, item) => item.type === membershipTypes.PUNCH_CARD ? (count + (item.quantity || 1)) : count, 0)
            return purchaseLimitations.properties.sessionsPerDay - quantityInCart
        }
        return null
    }, [purchaseLimitations, cart])

    useMembershipStartDate({values, setFieldValue, handleSubmit, blockOnLoggedOut, isCart: allowCart})

    const currencySymbol = useMemo(() => {
        if(box) {
            if(values.locations_box_fk) {
                const location = box.locations_box.find(location => location.id === values.locations_box_fk)
                return location?.currency_symbol
            } else if(selectedLocation) {
                const location = box.locations_box.find(location => location.id === selectedLocation)
                return location?.currency_symbol
            }
        }
    }, [box, values.locations_box_fk, selectedLocation]);
    const showNoMembershipOption = useMemo(() => {
        if(values.locations_box_fk && checkIfNoMembershipAllowed && box.locations_box.length) {
            const location = box.locations_box.find(location => location.id === values.locations_box_fk)
            return location?.disable_pages_app.some(item => item.section_name === 'registerWithoutMembership')
        }
        return false
    },[box, checkIfNoMembershipAllowed, values.locations_box_fk])
    const isNoPaymentLocation = useMemo(() => {
        if(selectedLocation) {
            const location = box.locations_box.find(location => location.id === selectedLocation)
            return !location?.hasPayments
        }
    }, [selectedLocation]);

    useEffect(() => {
        if(!skipStep) {
            setFieldValue('cart', null)
        } else {
            handleSubmit(values)
        }
    }, []);

    useEffect(() => {
        if(box && ((selectedLocation && !isHPGroupDisplay) || (isHPGroupDisplay && selectedHPGroup))) {
            getMemberships(selectedLocation)
        }
    }, [box, selectedLocation, selectedHPGroup]);

    useEffect(() => {
        if(hasPurchaseLimitations) {
            handlePurchaseLimitations()
        }
    }, [hasPurchaseLimitations]);

    useEffect(() => {
        if(globalLocation && (!values.locations_box_fk || !selectedLocation)) {
            // setFieldValue('locations_box_fk', globalLocation.id)
            setSelectedLocation(globalLocation.id)
        }
    }, [globalLocation]);

    useEffect(() => {
        if(selectedLocation) {
            if(isHPGroupDisplay) {
                const groupId = params.group ? parseInt(params.group) : null
                const isSpecificGroup = (!!groupId && groups.some(g => g.id === groupId)) ?? false;
                if(isSpecificGroup) {
                    setSelectedHPGroup(groupId)
                } else {
                    const selectedLocationGroupId = getHPGroupIdFromLocation(selectedLocation)
                    setSelectedHPGroup(selectedLocationGroupId)
                }
            }
            setFieldValue('locations_box_fk', selectedLocation)
            addFiltersToQueryParams(navigate, {location: selectedLocation})
            clearCart()
        }
    }, [selectedLocation]);

    useEffect(() => {
        if(box && !isHPGroupDisplay && locations.length === 0) onLinkClicked('/404', navigate)
    }, [locations]);

    useEffect(() => {
        if(flags?.closedDateModal || flags?.closedQuantityModal) {
            const itemToRemove = values.cart[values.cart.length - 1] //remove last item to enter the cart because the user closed one of the popups without selecting anything
            removeItemFromCart(itemToRemove)
        }
    }, [flags]);

    useEffect(() => {
        if(quantitySessionRes) {
            if(allowCart) {
                updateCartQuantity()
            } else {
                setFieldValue('cart', [{...values.cart[0], quantity: quantitySessionRes.quantity}])
            }
            dispatch(updateModalRes({modalName: SELECT_QUANTITY_MODAL, res: null}))
            setTimeout(() => {
                if(quantitySessionRes.quantity > 0) {
                    handleMembershipStartDate(values.cart[0], dispatch)
                }
            }, 100)
        }
    }, [quantitySessionRes]);

    const handlePurchaseLimitations = async () => {
        let res = await apiAction('site/checkPurchaseLimitations', 'post', {});
        setLimitations(res.data)
        return res.data
    }

    const getMemberships = async (locationId) => {
        setLoading(true)
        setMemberships(null)
        let params = {box_id: box.id, locations_box_id: locationId}
        if(isHPGroupDisplay) {
            params.group_id = selectedHPGroup
        } else {
            const locationHasMemberships = locations.some(l => l.id === locationId)
            if (!locationHasMemberships) {
                setSelectedLocation(locations[0].id)
                return null;
            }
        }
        let res = await apiAction('getSiteMemberships', 'post', params);
        let membershipsRes = res.data;

        if(isNoPaymentLocation) {
            membershipsRes = {plan: res.data.plan.filter(a => a.is_recurring_payment !== 1), session: res.data.session.filter(a => a.is_recurring_payment !== 1)}
        }
        setMemberships({plan: sortAlphabetically(membershipsRes.plan, 'name'), session: sortAlphabetically(membershipsRes.session, 'name')})
        setLoading(false)
        sendPixelEvent(pixelEvents.VIEW_CONTENT, {content_name: 'memberships page', content_ids: []})
    }

    const clearCart = () => {
        if(values.cart?.length && (params.overrideLocation !== 'true')) {
            const shouldClear = values.cart.some(item => item.location_box_fk !== selectedLocation)
            if(shouldClear) {
                setFieldValue('cart', null)
            }
        }
    }

    const onMembershipClick = (event, item) => {
        event.stopPropagation();
        const itemInCart = cart.find(i => i.id === item.id)
        if(itemInCart) {
            if(item.allow_quantity) {
                if(item.type === membershipTypes.PUNCH_CARD && maxSessionQuantity >= 0) {
                    dispatch(openModal({modalName: SELECT_QUANTITY_MODAL, props: {target: itemInCart, max: maxSessionQuantity + itemInCart.quantity}}))
                } else {
                    dispatch(openModal({modalName: SELECT_QUANTITY_MODAL, props: {target: itemInCart}}))
                }
            } else {
                removeItemFromCart(itemInCart)
            }
        } else {
            addItemToCart(item)
        }
    }

    const addItemToCart = async (item) => {
        if(item.type === membershipTypes.PUNCH_CARD && hasPurchaseLimitations) {
            const limits = await handlePurchaseLimitations()
            if(limits?.sessionsPerDay || (maxSessionQuantity === 0)) {
                dispatch(updateToastMessage({content: t('item-unavailable', {name: item.name, interpolation: {'escapeValue': false}}), title: t('unavailable-header'), type: 'error'}))
                return
            }
        }

        setFieldValue('cart', allowCart ? [...(values.cart ?? []), item] : [item])
        setTimeout(() => {
            if(item.type === membershipTypes.PUNCH_CARD && item.allow_quantity) {
                dispatch(openModal({modalName: SELECT_QUANTITY_MODAL, props: {max: maxSessionQuantity}}))
            } else {
                handleMembershipStartDate(item, dispatch)
            }
        },100)
    }

    const removeItemFromCart = (itemToRemove) => {
        const newCart = values.cart.filter(item => item.id !== itemToRemove.id)
        setFieldValue('cart', newCart)
        if(allowCart) {
            dispatch(updateToastMessage({content: t('cart-item-removed', {name: itemToRemove.name, interpolation: {'escapeValue': false}}), title: t('item-remove-success'), type: 'warning'}))
        }
    }

    const updateCartQuantity = () => {
        let newCart;
        if(quantitySessionRes.target) { //if it's update quantity action
            if(quantitySessionRes.quantity > 0) {
                newCart = values.cart.map((item) => item.id === quantitySessionRes.target.id ? ({...item, quantity: quantitySessionRes.quantity}) : item)
                setFieldValue('cart', newCart)
                // dispatch(updateToastMessage({content: t('cart-item-updated', {name: quantitySessionRes.target.name, quantity: quantitySessionRes.quantity}), title: t('item-update-success')}))
            } else { //if the quantity is 0, remove item from the cart
                removeItemFromCart(quantitySessionRes.target)
            }
        } else {
            //if there is no target then it's a newly added item in cart
            newCart = values.cart.map((item, i) => i === values.cart.length - 1 ? ({...item, quantity: quantitySessionRes.quantity}) : item)
            setFieldValue('cart', newCart)
        }
    }

    const onContinueWithoutMembershipClick = () => {
        dispatch(openModal({modalName: CONFIRMATION_MODAL, props: {
                header: t('continue-without-membership-confirm-header'),
                explanation: t('continue-without-membership-confirm-explain'),
                onConfirm: () => {
                    setFieldValue('continueWithoutMembership', true)
                    dispatch(updateFlags({continueWithoutMembership: true}))
                }
            }}))
    }

    const onCartPurchaseClick = () => {
        if(cart.length) {
            if (loggedIn) {
                handleSubmit()
            } else {
                dispatch(openModal({modalName: LOGIN_MODAL, props: {membership: cart}}))
            }
        }
    }

    const getHeaderInfoComp = (item) => {
        return (
            <>
                <span style={{width: isMobile ? '100%' : '27%'}}>{getMembershipPriceText(item, currencySymbol)}</span>
                <span style={{width: isMobile ? '100%' : '30%'}}>{getMembershipExpirationText(item)}</span>
            </>
        )
    }

    const getMembershipCtaConfig = (membership) => {
        if(membership.type === membershipTypes.PUNCH_CARD) {
            if(hasPurchaseLimitations && limitations.sessionsPerDay) return {ctaText: t('unavailable'), disableExpand: 'disabled'}
        }
        if(!allowCart) return {}
        const itemInCart = cart.find(item => item.id === membership.id)
        if(itemInCart) {
            if(membership.allow_quantity) return {ctaText: t('update')}
            return {ctaText: t('remove')}
        } else {
            if (cartHasRecurring) return {ctaText: '', disableExpand: 'disabled'} //if cart has a HOK mt, disable all memberships
            if (membership.is_recurring_payment && !cartHasRecurring && cart.length) return {ctaText: '', disableExpand: 'disabled'} //id cart has no HOK, disable HOK memberships
        }
        return {ctaText: t('add')}
    }

    const getCollapseInfo = (item) => {
        if(isMobile) {
            return (
                <FlexColumnSection gap={'20px'} flex={'none'}>
                    {!item.description && item.type !== 'session' && item.limitations?.length === 0 && <SmallText>{t('membership-collapse-empty')}</SmallText>}
                    <FlexColumnSection flex={'unset'}>
                        {item.description &&
                        <FlexColumnSection flex={'unset'}>
                            <SmallText weight={'bold'}>{t('description')}:</SmallText>
                            <SmallText>{item.description}</SmallText>
                        </FlexColumnSection>
                        }
                        {item.type === 'session' && <SmallText><span style={{fontWeight: '600'}}>{t('price-per-session')}:</span>{` ${currencySymbol} ${(item.price/item.sessions).toFixed(1)}`}</SmallText>}
                    </FlexColumnSection>
                    {item.limitations?.length > 0 &&
                    <FlexColumnSection gap={'20px'} flex={'none'}>
                        {item.limitations.map(limitation => (
                            <FlexColumnSection key={`mobile-item-${item.id}-limitation-${limitation.header}`} flex={'none'}>
                                <SmallText weight={'bold'}>{limitation.header}</SmallText>
                                {limitation.values.map((value, i) => <SmallText key={`mobile-item-${item.id}-limitation-${limitation.header}-${i}`}>{value}</SmallText>)}
                            </FlexColumnSection>
                        ))}
                    </FlexColumnSection>
                    }
                </FlexColumnSection>
            )
        }
        return (
            <FlexRowSection align={'start'}>
                <FlexColumnSection flex={'unset'}>
                    {item.description &&
                        <FlexColumnSection flex={'unset'}>
                            <SmallText weight={'bold'}>{t('description')}:</SmallText>
                            <SmallText>{item.description}</SmallText>
                        </FlexColumnSection>
                    }
                    {item.type === 'session' && <SmallText><span style={{fontWeight: '600'}}>{t('price-per-session')}:</span>{` ${currencySymbol} ${(item.price/item.sessions).toFixed(1)}`}</SmallText>}
                </FlexColumnSection>
                {(item.description || item.type === 'session') && item.limitations?.length > 0 && <SeparatorVerticalDiv color={Colors.greyText} height={'100%'} margin={'0 30px'}/>}
                {item.limitations?.length > 0 &&
                    <div style={{display: 'grid', gridTemplateColumns: item.description ? 'auto auto' : 'auto auto auto', columnGap: '20px', rowGap: '15px', width:'100%'}} key={`item-${item.id}-limitations`}>
                        {item.limitations.map(limitation => (
                            <FlexColumnSection key={`item-${item.id}-limitation-${limitation.header}`}>
                                <SmallText weight={'bold'}>{limitation.header}</SmallText>
                                {limitation.values.map((value,i) => <SmallText key={`item-${item.id}-limitation-${limitation.header}-${i}`}>{value}</SmallText>)}
                            </FlexColumnSection>
                        ))}
                    </div>
                }
                {!item.description && item.type !== 'session' && item.limitations?.length === 0 && <SmallText>{t('membership-collapse-empty')}</SmallText>}
            </FlexRowSection>
        )
    }

    return (
        <FlexColumnPadding gap={'24px'} padding={isMobile ? '0 16px' : 0}>
            {!hideLocation &&
            <FlexRowSection flex={'none'}>
                {selectedLocation && memberships && locations.length > 1 && <CustomSelect onChange={(val) => val ? setSelectedLocation(val) : null} options={locations} defaultValue={selectedLocation} style={{flex: 1, maxWidth: '250px'}}/>}
                {selectedHPGroup && memberships && <CustomSelect onChange={(val) => val ? setSelectedHPGroup(val) : null} options={groups} defaultValue={selectedHPGroup} style={{flex: 1, maxWidth: '250px'}}/>}
            </FlexRowSection>}
            <Spin spinning={loading} style={{flex: 1}}/>
            {
                (memberships || showNoMembershipOption) &&
                <FlexColumnSection gap={'24px'} overflow={'hidden auto'}>
                    { showNoMembershipOption &&
                        <CustomCollapse headerText={t('register-without-membership')} ctaText={t('continue')} headerWidth={'auto'} headerInfo={<></>} onBtnClick={onContinueWithoutMembershipClick} key={'register-without-membership'}>
                            <SmallText>{t('register-without-membership-collapse')}</SmallText>
                        </CustomCollapse>
                    }
                    {
                        memberships && memberships.plan.length > 0 &&
                        <FlexColumnSection gap={'12px'} overflow={'unset'} flex={'none'}>
                            {memberships.session.length > 0 && <SmallHeader>{t('memberships')}</SmallHeader>}
                            {
                                memberships.plan.map(item => (
                                    <CustomCollapse {...getMembershipCtaConfig(item)} headerText={item.name} headerInfo={getHeaderInfoComp(item)} onBtnClick={(event) => onMembershipClick(event, item)} key={`plan-membership-${item.id}`}>
                                        {getCollapseInfo(item)}
                                    </CustomCollapse>
                                ))
                            }
                        </FlexColumnSection>
                    }
                    {
                        memberships && memberships.session.length > 0 &&
                        <FlexColumnSection gap={'12px'} overflow={'unset'}>
                            {memberships.plan.length > 0 && <SmallHeader>{t('session-cards')}</SmallHeader>}
                            {
                                memberships.session.map(item => (
                                    <CustomCollapse {...getMembershipCtaConfig(item)} headerText={item.name} headerInfo={getHeaderInfoComp(item)} onBtnClick={(event) => onMembershipClick(event, item)} key={`session-membership-${item.id}`}>
                                        {getCollapseInfo(item)}
                                    </CustomCollapse>
                                ))
                            }
                        </FlexColumnSection>
                    }
                </FlexColumnSection>
            }
            {allowCart && <CartBanner values={values} handleSubmit={onCartPurchaseClick}/>}
        </FlexColumnPadding>
    );
};

export default Memberships;

const FlexColumnPadding = styled(FlexColumnSection)`
    padding: ${({padding}) => padding ?? 0};
`;